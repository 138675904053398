import type { ReactElement } from 'react'

import styled from 'styled-components'

import Heading from 'components/Heading'
import type { ServerImageProps } from 'components/Image/Relay/ServerImage'
import Paragraph from 'components/Paragraph'
import Pill from 'components/Pill'
import Box from 'components/Primitives/Box'
import Set from 'components/Set'
import { fonts } from 'theme'

type ImageType = ReactElement<ServerImageProps>

export type BannerAdvertBaseProps = {
    title: string
    description: string | null
    image: ImageType
    companyName?: string
}

const isSponsoredAdvert = (companyName: BannerAdvertBaseProps['companyName']) => {
    return companyName !== 'finimize'
}

const BannerAdvertBase = ({ title, description, image, companyName }: BannerAdvertBaseProps) => {
    return (
        <Container>
            <Set spacing={0.75} isVertical>
                <ImageContainer paddingBottom={['50%', '50%', '25%']}>{image}</ImageContainer>
                <Set spacing={0.5} alignItems="center">
                    {isSponsoredAdvert(companyName) && (
                        <Pill
                            backgroundColor="accent.100"
                            color="accent.500"
                            fontSize={fonts.size.XXS}
                        >
                            Sponsored
                        </Pill>
                    )}
                    <Paragraph margin="0px" fontSize={fonts.size.XXS}>
                        {title}
                    </Paragraph>
                </Set>
                {!!description && (
                    <Heading as="h3" mt="0px" fontSize={fonts.size.S}>
                        {description}
                    </Heading>
                )}
            </Set>
        </Container>
    )
}

const Container = styled.div`
    background-color: ${({ theme }) => theme.palette.neutral[50]};
    border-radius: ${({ theme }) => theme.radii.medium};
    padding: ${({ theme }) => theme.space[1]};
    margin-bottom: ${({ theme }) => theme.space[1]};
`

const ImageContainer = styled(Box)`
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: ${({ theme }) => theme.radii.medium};
`
export default BannerAdvertBase
